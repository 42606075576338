<template>
  <section class="news">
    <!--<v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      max-height="420"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
      </v-layout>
    </v-img>-->
    <v-container  grid-list-md text-center class="ctk-div" v-if="detail">
      <v-layout>
        <v-flex xs12 sm12 md12 lg12 class="text-left">
            <h3 style="font-size: 32px; color: #333; margin-bottom:20px;">{{detail.title}}</h3>
            <h6 style="font-size: 14px; color: #999; font-weight:normal; margin-bottom:20px;">发布时间: {{ detail.create_time }}</h6>
            <div  v-html="detail.content" class="ctk-news-content">
              {{detail.content}}
            </div>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 4,
    detail: null,
    news: {
      'introduction': null
    }
  }),
  created () {
    document.querySelector('#menu_news').classList.add('v-btn--active')
    this.getNew(this.$route.params)
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getNew (param) {
      this.https.get('new', param).then(response => {
        if (response.code === 0) {
          this.detail = response.data.detail
          this.recommend = response.data.recommend
          this.tabSubMenu = '/news/' + this.detail.type
        }
      })
    }
  }
}
</script>
<style>
  /*.ctk-news-content img {width:100%;}*/
</style>
